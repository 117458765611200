<template>
  <main class="container mx-auto">
    <div class="relative bg-white overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
            <Headerlinks />
          </div>
          <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div class="sm:text-center lg:text-left">
              <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl font-display">
                <prismic-rich-text v-if="fields.title1" :field="fields.title1" wrapper="span" class="block xl:inline"/>
              </h1>
              <prismic-rich-text v-if="fields.sub_title" :field="fields.sub_title" wrapper="div" class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"/>
            </div>
          </main>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <prismic-image v-if="fields.hero_image" :field="fields.hero_image" class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full pb-10" src="../assets/unpacking.jpg" alt="" />
      </div>
    </div>
  </main>
</template>

<script>
import Headerlinks from "./Headerlinks";

export default {
  name: 'Headerhero',
  components: {
    Headerlinks
  },
  data() {
    return {
      fields: {
        title1: null,
        title2: null,
        sub_title: null,
        hero_image: null
      }
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.getSingle('homepage')
        .then((document) => {
          this.fields = document.data;
        })
    }
  },
  created() {
    this.getContent();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  h1 strong { 
    @apply text-peach-600; 
  }
</style>
