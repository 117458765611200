<template>
    <div class="relative bg-white overflow-hidden">
        <div class="max-w-7xl mx-auto">
            <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
                <Headerlinks />
            </div>
        </div>
    </div>
</template>

<script>
import Headerlinks from "./Headerlinks";

export default {
    name: "Header",
    components: {
        Headerlinks
    }
}
</script>