<template>
  <div>
    <Header />
    <div class="bg-white max-w-7xl mx-auto pt-16 pb-16 px-4 sm:px-6 lg:pt-16 lg:pb-16 lg:py-16 lg:px-8">
      <div class="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            <prismic-rich-text v-if="fields.title" :field="fields.title" wrapper="span"></prismic-rich-text>
          </h2>
          <div class="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-3 lg:items-center">
            <p class="text-xl text-gray-500">
              <prismic-rich-text v-if="fields.description" :field="fields.description" wrapper="div"></prismic-rich-text>
            </p>
            <div class="items-center text-peach-600 font-semibold justify-self-end" v-if="message != ''">{{ this.message }}</div>
            <form v-if="!this.isHidden" class="mt-6 flex flex-col grid-cols-2 lg:grid-cols-2 gap-3 sm:flex-row lg:mt-0 lg:justify-end items-center">
              <div class="sm:grid-cols-2">
                <label for="email-address" class="sr-only text-gray-500">Email address</label>
                <input id="email" name="email-address" type="email" v-model="email" autocomplete="email" required class="appearance-none w-full px-4 py-2 border border-gray-300 text-base rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:ring-peach-500 focus:border-peach-500 lg:max-w-xs" placeholder="Enter your email">
              </div>
              <div class="mt-2 flex-shrink-0 w-auto flex rounded-md shadow-sm sm:mt-0 sm:ml-3 inline-flex">
                <button @click="submit" type="submit" class="w-auto bg-peach-600 px-4 py-2 border border-transparent rounded-md flex sm:items-center justify-center text-base font-medium text-white hover:bg-peach-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-peach-500 sm:w-auto sm:inline-flex">
                  Notify me
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          <!-- NewsPost goes under here -->
          <news-posts v-for="post in posts" :key="post.id" v-bind:post="post" class="mx-0"></news-posts>
          <!-- To here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import NewsPosts from '../components/NewsPosts.vue'
import Header from '../components/Header.vue'
import NewsPosts from '../components/NewsPosts.vue'
const axios = require('axios');

export default {
  name: 'news',
  components: {
    //NewsPosts,
    Header,
    NewsPosts
  },
  data () {
    return {
      email: '',
      documentId: '',
      message: '',
      fields: {
        title: null,
        description: null,
      },
      posts: [],
      linkResolver: this.$prismic.linkResolver,
      hasContent: false,
      isHidden: false
    }
  },
  methods: {
    getContent () {
      //Query to get home content
      this.$prismic.client.getSingle('news')
        .then((document) => {
          if (document) {
            this.documentId = document.id
            this.fields.title = document.data.title;
            this.fields.description = document.data.description;

            //Check that the blog home contains content
            this.checkForContent();

          } else {
            //returns error page
            this.$router.push({ name: 'not-found' })
          }
        })
    },
    getPosts () {
      //Query to get blog posts
      this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'news_post'),
        { orderings : '[my.news_post.date desc]' }
      ).then((response) => {
        this.posts = response.results;
      })
    },
    //Function to check for any content on the blog home page
    checkForContent(){
      if (
        this.fields.image != undefined ||
        this.$prismic.richTextAsPlain(this.fields.title) !== "" ||
        this.$prismic.richTextAsPlain(this.fields.description) !== ""
      ) {
        this.hasContent = true;
      }
    },
    submit() {
      axios.post('/subscribe', null, { params: {
        email: this.email,
        message: this.message,
        isHidden: false
      }})
      .then((response) => {
        console.log(response);
        this.isHidden = !this.isHidden;
        this.message = "Subscribed!";
      }, (error) => {
        console.log(error);
      });
      this.email = '';
    }
  },
  created () {
    this.getContent()
    this.getPosts()
  },
}
</script>