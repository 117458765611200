<template>
  <div id="app" class="container mx-auto">
      <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import "tailwindcss/tailwind.css"

export default {
  name: 'App',
  components: {
    Footer
  },
  data() {
    return {

    }
  }
}
</script>

<style src="./assets/css/tailwind.css"/>

