<template>
    <div>
        <div class="container bg-white" id="about">
            <div class="bg-gray-50 pt-12 sm:pt-16">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="max-w-4xl mx-auto text-center">
                        <prismic-rich-text v-if="about_top.primary" :field="about_top.primary.about_title" wrapper="div" class="text-3xl font-extrabold text-gray-900 sm:text-4xl"/>
                        <prismic-rich-text v-if="about_top.primary" :field="about_top.primary.about_description" wrapper="div" class="pb-12 mt-3 text-xl text-gray-500 sm:mt-4"/>
                    </div>
                </div>
                <div class="pb-12 bg-white sm:pb-16">
                    <div class="relative">
                        <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
                        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div class="max-w-4xl mx-auto">
                                <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                                    <div v-for="(item, index) in about_top.items" :key="'about_us_top-item-' + index">
                                        <div class="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                            <prismic-rich-text v-if="item.about_name" :field="item.about_name" wrapper="dt" class="order-2 mt-2 text-lg leading-6 font-medium text-gray-500"/>
                                            <prismic-rich-text v-if="item.about_number" :field="item.about_number" wrapper="dd" class="order-1 text-5xl font-extrabold text-peach-600"/>
                                        </div>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="max-w-7xl mx-auto mt-10 px-4 sm:px-6 lg:px-8">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                <div v-for="(item, index) in about_bottom.items" :key="'about_us-item-' + index">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div v-if="item.icon && item.icon.length > 0" class="icon flex items-center justify-center h-12 w-12 rounded-md bg-peach-500 stroke-current text-white" v-html="item.icon[0].text"/>
                        </div>
                        <div class="ml-4">
                            <prismic-rich-text v-if="item.category_title" :field="item.category_title" wrapper="dt" class="text-lg leading-6 font-medium text-gray-900"/>
                            <prismic-rich-text v-if="item.category_description" :field="item.category_description" wrapper="dd" class="mt-2 text-base text-gray-500"/>
                        </div>
                    </div>
                </div>
            </dl>
        </div>
    </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      slices: [],
      about_top: {},
      about_bottom: {}
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.getSingle('homepage')
        .then((document) => {
          this.slices = document.data.body;
          for(let slice in this.slices) {
              if(this.slices[slice].slice_type === 'about_us_top') {
                this.about_top = this.slices[slice];
              } 
              else if(this.slices[slice].slice_type === 'about_us') {
                this.about_bottom = this.slices[slice];
              }
          }
        })
    }
  },
  created() {
    this.getContent();
  }
}
</script>

<style>
    .icon svg {
        @apply h-6;
        @apply w-6;
    }
</style>