import Vue from 'vue';
import App from './App.vue';
import router from './router';
import PrismicVue from '@prismicio/vue';
import linkResolver from './prismic/link-resolver';
import '@/assets/css/tailwind.css'


Vue.config.productionTip = false

// Add this before the new Vue instance
Vue.use(PrismicVue, {
  endpoint: "https://nilegroup.cdn.prismic.io/api/v2",
  linkResolver
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
