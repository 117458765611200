<template>
  <div class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8" id="brands">
    <section v-for="(slice, index) in slices" :key="'slice-' + index">
      <template v-if="slice.slice_type === 'brand'" class="slice-brand">
        <div class="absolute inset-0">
          <div class="bg-white h-1/3"></div>
        </div>
        <div class="relative max-w-7xl mx-auto">
          <div class="text-center">
            <prismic-rich-text v-if="slice.primary.title" :field="slice.primary.title" wrapper="div" class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"/>
            <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            </p>
          </div>
          <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            <div v-for="(item, index) in slice.items" :key="'brand-item-' + index">
              <div class="flex flex-col rounded-lg shadow-lg overflow-hidden h-full">
                <div class="flex-shrink-0">
                      <div class="h-48 object-cover"> 
                          <prismic-image v-if="item.image" :field="item.image" class="object-center"/>
                      </div>
                </div>
                <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div class="flex-1">
                    <a href="#" class="block mt-2">
                      <p class="text-xl font-semibold text-gray-900 object-centre pb-5">
                          <prismic-image v-if="item.logo" :field="item.logo" class="h-12 w-full"/>
                      </p>
                      <prismic-rich-text v-if="item.description" :field="item.description" class="mt-3 text-base text-gray-500"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </section>
  </div>
  
</template>

<script>
export default {
  name: "Brands",
  data() {
    return {
      slices: []
    }
  },
  methods: {
    getContent() {
      this.$prismic.client.getSingle('homepage')
        .then((document) => {
          this.slices = document.data.body;
        })
    }
  },
  created() {
    this.getContent();
  }
}
</script>