<template>
    <div>
        <Header />
        <div class="relative py-16 bg-white overflow-hidden" id="post"> <!--Post starts here-->
            <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                        <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                        </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                        <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                        </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                        <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                        </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div class="relative px-4 sm:px-6 lg:px-8">
                <div class="back">
                    <router-link to="/news">back to list</router-link>
                </div>
                <!-- Button to edit document in dashboard -->
                <div>
                    <prismic-rich-text v-if="fields.title" :field="fields.title" class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"></prismic-rich-text>
                    <div class="text-sm text-gray-500 mt-2 block text-center leading-8 tracking-tight">{{ Intl.DateTimeFormat('en-AEST', dateOptions).format(new Date(fields.date)) }}</div>
                </div>
                
                <!-- Slice Block Componenet tag -->
                <slices-block :slices="slices"/>
            </div>
        </div>
    </div>
</template>


<script>
import Header from '../components/Header.vue';
//Importing all the slices components
import SlicesBlock from '../components/SlicesBlock.vue'

export default {
  name: 'post',
  components: {
    Header,
    SlicesBlock
  },
  data () {
    return {
      dateOptions: { year: 'numeric', month: 'short', day: '2-digit' },
      fields: {
        title: null,
        date: null,
      },
      slices: []
    }
  },
  methods: {
    getContent (uid) {
      //Query to get post content
      this.$prismic.client.getByUID('news_post', uid)
        .then((document) => {
          if (document) {
            this.fields.title = document.data.title
            this.fields.date = document.data.date
            
            //Set slices as variable
            this.slices = document.data.body
          } 
          else {
            //returns error page
            this.$router.push({ name: 'not-found' })
          }
        })
    }
  },
  created () {
    this.getContent(this.$route.params.uid)
  },
  beforeRouteUpdate (to, from, next) {
    this.getContent(to.params.uid)
    next()
  }
}
</script>

<style scoped>
h1 strong { 
    @apply text-peach-600;
    @apply uppercase;
    @apply text-base;
    @apply font-semibold;
    @apply tracking-wide;
}
.back {
  color: #9A9A9A;
  display: block;
  max-width: 700px;
  margin: 0 auto 2em auto;
  font-size: 16px;
}
.back:before {
  content: '←';
  display: inline-block;
  position: relative;
  margin-right: 8px;
}
.back a {
  color: #9A9A9A;
}
.back a:hover {
  text-decoration: underline;
}
</style>