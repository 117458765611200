<template>
    <div id="home">
        <Headerhero />
        <About />
        <Brands />
    </div>
</template>

<script>
import Headerhero from '../components/Headerhero.vue';
import About from '../components/About.vue';
import Brands from '../components/Brands.vue';

export default {
    name: "Home",
    components: {
        Headerhero,
        About,
        Brands
    }
};
</script>
