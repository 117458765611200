import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import News from "../views/News.vue";
import About from "../components/About.vue";
import Brands from "../components/Brands.vue";
import Footer from "../components/Footer.vue";
import Post from '../views/Post.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    //base: process.env.BASE_URL,
    scrollBehavior: function(to) { 
        if(to.hash) {
            return {selector: to.hash}
        } else {
            return { x: 0, y: 0}
        }
    },
    routes: [
        {
            path: "/",
            name: "Home",
            component: Home
        },
        {
            path: "/about",
            name: "About",
            component: About
        },
        {
            path: "/brands",
            name: "Brands",
            component: Brands
        },
        {
            path: "/contact",
            name: "Contact Us",
            component: Footer
        },
        {
            path: "/news",
            name: "News",
            component: News
        },
        {
            path: "/post/:uid",
            name: "Post",
            component: Post
        }
    ]
});

export default router;