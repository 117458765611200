<template>
    <!-- Template for news posts -->
      <div class="mb-5">
        <p class="text-sm text-gray-500">
          <span>{{ Intl.DateTimeFormat('en-AEST', dateOptions).format(new Date(post.data.date)) }}</span>
        </p>
        <div class="mt-2 block">
          <p class="text-xl font-semibold text-gray-900">
            <prismic-rich-text v-if="post.data.title" :field="post.data.title"/>
          </p>
          <p class="mt-3 text-base text-gray-500">
            {{getFirstParagraph(post)}}
          </p>
        </div>
        <div class="mt-3">
          <router-link :to="linkResolver(post)" class="text-base font-semibold text-peach-600 hover:text-peach-500">
            Read full story
          </router-link>
        </div>
      </div>
</template>

<script>
export default {
  name: 'news-posts',
  props: ['post'],
  data () {
    return {
      posts: [],
      dateOptions: { year: 'numeric', month: 'short', day: '2-digit' },
      linkResolver: this.$prismic.linkResolver
    }
  },
  methods: {
    /*
    getPosts () {
      //Query to get blog posts
      this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'news_post'),
        { orderings : '[my.news_post.date desc]' }
      ).then((response) => {
        this.posts = response.results;
      })
    },
    */
    //Function to get the first paragraph of text in a blog post and limit the displayed text at 300 characters
    getFirstParagraph (post) {
      const textLimit = 300;
      const slices = post.data.body;
      let firstParagraph = '';
      let haveFirstParagraph = false;

      slices.map(function(slice) {
        if (!haveFirstParagraph) {
          if (slice.slice_type == "text") {
            slice.primary.text.forEach(function(block){
              if (block.type == "paragraph") {
                if (!haveFirstParagraph) {
                  firstParagraph += block.text;
                  haveFirstParagraph = true;
                }
              }
            });
          }
        }
      });
      
      const limitedText = firstParagraph.substr(0, textLimit)

      if (firstParagraph.length > textLimit) {
        return limitedText.substr(0, limitedText.lastIndexOf(' ')) + '...';
      }
      else {
        return firstParagraph;
      }
    },
  },
  created () {
    /*
    this.getPosts()
    */
  }
}
</script>