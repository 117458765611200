<template>
    <nav class="relative lg:justify-start sm:flex sm:justify-between" aria-label="Global" id="nav_links">
        <div class="relative flex items-center justify-between sm:h-10 lg:justify-start">
            <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div class="flex items-center justify-between w-full md:w-auto">
                    <router-link to="/"><img class="h-8 w-auto sm:h-10" src="../assets/logo.svg" alt="The Nile Logo" title="The Nile"/></router-link>
                    <div class="-mr-2 flex items-center sm:hidden">
                        <button @click="isOpen = !isOpen" type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-peach-500" aria-expanded="false">
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div :class="isOpen ? 'block' : 'hidden'" class="ml-1 sm:ml-10 mt-5 sm:mt-0 px-4 pt-2 pb-4 md:space-x-8 sm:flex sm:bg-white bg-gray-50 rounded-md">
            <router-link to="/#about" class="block font-medium text-gray-500 hover:text-gray-900 pt-4 sm:pt-0 sm:ml-3">About Us</router-link>
            <router-link to="/#brands" class="block font-medium text-gray-500 hover:text-gray-900 pt-4 sm:pt-0 sm:ml-3">Brands</router-link>
            <router-link to="/#footer" class="block font-medium text-gray-500 hover:text-gray-900 pt-4 sm:pt-0 sm:ml-3">Contact Us</router-link>
            <router-link to="/news" class="block font-medium text-gray-500 hover:text-gray-900 pt-4 sm:pt-0 sm:ml-3">News</router-link>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Headerlinks",
    data() {
        return {
           isOpen: false
        }
    }
}
</script>