<template>
  <footer class="bg-white" aria-labelledby="footerHeading" id="footer">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8 xl:col-span-1">
          <img class="h-10" src="../assets/logo.svg" alt="Company name">
          <p class="text-gray-500 text-base">
          </p>
          <div class="flex space-x-6">
            <a href="https://www.linkedin.com/company/the-nile" target="_blank" class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Linkedin</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                  clip-rule="evenodd" />
              </svg>
            </a>
          </div>
        </div>
        <div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div class="md:grid md:grid-cols-1 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Contact Us
              </h3>
              <ul class="mt-4 space-y-4">

                <li>
                  <a href="mailto:info@nilegroup.com.au" class="text-base text-gray-500 hover:text-gray-900">
                    info@nilegroup.com.au
                  </a>
                </li>

                <li>
                  <p class="text-base text-gray-500">Head Office<br>42 Apex Drive<br>Truganina VIC 3029<br>Australia</p>
                </li>

              </ul>
            </div>

          </div>
          <div class="md:grid md:grid-cols-1 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Our Brands
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a href="https://www.thenile.com.au" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">
                    The Nile (AU/</a><a href="https://www.thenile.co.nz" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">NZ)</a>
                </li>

                <li>
                  <a href="https://www.tinyfox.com.au" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">
                    Tiny Fox (AU/</a><a href="https://www.tinyfox.co.nz" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">NZ)</a>
                </li>

                <li>
                  <a href="https://www.beginswithb.com.au/" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">
                    Begins with B (AU/</a><a href="https://www.beginswithb.co.nz/" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">NZ)</a>
                </li>

                <li>
                  <a href="https://www.wellcooked.com.au/" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">
                    Well Cooked (AU/</a><a href="https://www.wellcooked.co.nz/" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">NZ)</a>
                </li>

                <li>
                  <a href="https://www.mobythegreat.com" target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900">
                    Moby the Great (US)
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8">
        <p class="text-base text-gray-400 xl:text-center">
          &copy; 2024 The Nile Group. All rights reserved.
        </p>
    </div>
  </div>
</footer></template>

<script>
export default {
  name: "Footer"
}
</script>